/* 越南语 */
export default {
  emailRegister: 'Đăng ký Email',
  registerNotice: 'Tài khoản chưa đăng ký sẽ được tự động đăng ký và đăng nhập',
  enterEmail: 'Vui lòng nhập địa chỉ email của bạn',
  enterCode: 'Vui lòng nhập mã của bạn',
  getCode: 'Nhận mã',
  signIn: 'Đăng nhập ngay',
  signPropt: 'Bằng cách đăng nhập, bạn đồng ý với',
  policy: 'Chính sách Bảo mật',
  agreement: 'Thỏa thuận Người dùng',
  emailRuleError: 'Định dạng email không chính xác',

  starcard: `Thẻ Ngôi Sao Thành Viên`,
  more: `Thêm`,
  describe: `Thẻ Ngôi Sao là bằng chứng về quyền lợi trong hệ thống quảng bá của Jubaopen. Chỉ những người sở hữu Thẻ Ngôi Sao mới có thể hưởng các phúc lợi và quyền lợi trong hệ thống quảng bá của Jubaopen. Thẻ Ngôi Sao được chia thành Thẻ Ngôi Sao Trải Nghiệm và Thẻ Ngôi Sao Chuyên Dụng. Người dùng Thẻ Ngôi Sao có thể nhận được phần thưởng tiền mặt.`,
  starReward: `Phần thưởng quảng bá Thẻ Ngôi Sao`,
  unlock: `Mở khóa`,
  text1: `· Phần thưởng mời: Người được mời trực tiếp được thưởng 10% khi trở thành M-Card.`,
  text2: `· Phần thưởng khuyến mãi đầu tư bản quyền: Người được mời trực tiếp đầu tư bản quyền và nhận được 10% thu nhập hàng ngày của người được mời.`,
  text3: `· Phần thưởng xem phim: Mời trực tiếp người dùng mở khóa một tập phim có thể nhận được phần thưởng 40%, hoặc đăng ký thành viên phim ngắn có thể nhận được phần thưởng 20%.`,
  starClu: `Phần thưởng nhóm ngôi sao`,
  text4: `Vui lòng liên hệ chăm sóc khách hàng hoặc người mời để biết quy tắc cụ thể`,
  copyright: `Gây quỹ bản quyền`,
  td1: `Tỷ suất lợi nhuận hàng năm`,
  td2: `Số tiền đầu tư ban đầu`,
  td3: `Đã hoàn thành/Tổng số tiền`,
  warning: `*Lợi nhuận sẽ dao động hàng ngày tùy thuộc vào hiệu quả quảng bá thực tế.`,
  schedule: `Tiến độ gây quỹ`,
  buynow: `Mua ngay`,
  download: `Tải xuống`,
  go: `Đi đến`,
  android: `Android`,
  apple: `App Store`,
  Characteristic: `Đặc điểm`,
  Participation: `Số tiền tham gia`,
  method: `Phương thức thanh toán`,
  Payamount: `Số tiền thanh toán`,
  jump: `Chuyển đến thanh toán`,
  name1: `Phim ngắn`,
  name2: `Nhiều loại`,
  name3: `Thành viên Thẻ Ngôi Sao`,
  name4: `Thu nhập từ ống dẫn`,
  describe1: `Bảo đảm chất lượng`,
  describe2: `Tưởng tượng điều bạn muốn`,
  describe3: `Trợ cấp hàng tỷ`,
  describe4: `Ngành phụ đứng đầu`,
  warning1: `Nội dung nhập không thể để trống`,
  success1: `Liên kết thành công`,
  ends: `Đếm ngược kết thúc`,
  warning2: `Bạn chưa đăng nhập, vui lòng đăng nhập trước`,

  experienceStar: 'Thẻ Ngôi Sao Trải Nghiệm',
  ExclusiveStar: 'Thẻ Ngôi Sao Độc Quyền',
  noCard: 'Không Có Thẻ Ngôi Sao',

  aboutus: `Về chúng tôi`,
  understand: `Hiểu về chúng tôi`,
  Snapshort: `Đội ngũ ${window.name}:`,
  Innovators: `Tái tạo`,
  industry: `Nhà đổi mới trong ngành công nghiệp phim ảnh`,
  paragraph1_1: `Trong thời đại kỹ thuật số thay đổi nhanh chóng, một nhóm các nhà phát triển yêu thích nghệ thuật điện ảnh đang cống hiến để tái tạo trải nghiệm xem phim của khán giả.`,
  paragraph1_2: `Đội ngũ của chúng tôi, ${window.name}, với trọng tâm là đổi mới và điều hành bởi công nghệ, đang dẫn đầu xu hướng mới trong ngành công nghiệp phim ảnh.`,
  paragraph1_3: `${window.name} là một ứng dụng di động tập trung vào nội dung phim ngắn. Chúng tôi lựa chọn cẩn thận những bộ phim ngắn chất lượng cao để phù hợp với lối sống nhanh chóng của khán giả hiện đại.`,
  paragraph1_4: `Ứng dụng của chúng tôi cho phép người dùng xem phim ngắn mới nhất và phổ biến nhất bất cứ lúc nào, đồng thời cung cấp các đề xuất cá nhân hóa phong phú, giúp mỗi người dùng đều có thể tìm thấy nội dung mình yêu thích.`,
  paragraph2_1: `Đội ngũ của chúng tôi gồm các nhà phát triển có kinh nghiệm và tay nghề cao, thành thạo các công nghệ sản xuất nội dung phim ảnh, phân tích dữ liệu lớn và đề xuất trí tuệ nhân tạo.`,
  paragraph2_2: `Điều này cho phép chúng tôi sản xuất và cập nhật nội dung một cách hiệu quả, đồng thời cung cấp các đề xuất tùy chỉnh cho từng người dùng.`,
  paragraph2_3: `Tầm nhìn của chúng tôi là giúp mỗi người dùng tìm thấy niềm vui và cảm hứng trong lực hút của các bộ phim. Chúng tôi tin rằng, thông qua nỗ lực của chúng tôi, ${window.name} sẽ trở thành một phần trong cuộc sống của bạn, giúp bạn tìm thấy khoảnh khắc yên bình và hạnh phúc trong cuộc sống bận rộn.`,
  paragraph3_1: `Dù bạn đang tìm kiếm giải trí nhẹ nhàng vui vẻ hay những câu chuyện gợi suy tư, ${window.name} đều có thể đáp ứng nhu cầu của bạn.`,
  paragraph3_2: `Hãy cùng chúng tôi khám phá những khả năng vô hạn của nghệ thuật điện ảnh.`,

  period: `Thẻ dùng thử`,
  Premier: `Thẻ cao cấp`,
  Expiration: `Ngày hết hạn:`,
  renew: `Gia hạn ngay`,
  balance: `Số dư Thẻ Ngôi Sao`,
  Income: `Thu nhập`,
  wallet: `Chuyển vào ví`,
  daily: `Nhiệm vụ hàng ngày`,
  nocompleted: `Hôm nay chưa hoàn thành`,
  code: `Mã VIP`,
  Residue: `Mã còn lại`,
  reward: `Phần thưởng hôm nay`,
  type: `Loại`,
  amount: `Số tiền`,
  time: `Thời gian`,
  none: `Hiện tại không có thêm nội dung`,
  load: `Đang tải...`,
  loadmore: `Tải thêm`,
  success2: `Chuyển vào ví thành công`,
  five: `Chuyên gia năm sao`,
  four: `Chuyên gia bốn sao`,
  Samsung: `Chuyên gia ba sao`,
  two: `Chuyên gia hai sao`,
  one: `Chuyên gia một sao`,
  recommendation: `Phần thưởng đề xuất`,
  rewards: `Phần thưởng`,
  taskRewards: `Phần thưởng nhiệm vụ`,
  starRewards: `Phần thưởng khuyến mãi bản quyền`,
  MovieRewards: `Phần thưởng khuyến mãi thẻ M`,

  annualized: `Tỷ suất lợi nhuận hàng năm`,
  Initial: `Số tiền đầu tư ban đầu`,
  Completed: `Đã hoàn thành/Tổng số tiền`,
  tishi: `*Lợi nhuận sẽ dao động hàng ngày tùy thuộc vào hiệu quả quảng bá thực tế.`,
  Crowdfunding: `Tiến độ gây quỹ:`,
  dividend: `Số dư lợi tức gây quỹ`,
  Project: `Tên dự án`,
  Cumulative: `Tổng thu nhập`,
  Dividend: `Tỷ suất lợi nhuận`,
  investment: `Số tiền đầu tư`,
  name5: `Gây quỹ`,
  name6: `Tham gia`,
  title1: `Đang tiến hành`,
  title2: `Đã kết thúc`,
  title3: `Hồ sơ tham gia`,
  title4: `Chi tiết thu nhập`,
  text5: `Thời gian mua`,
  text6: `Thời gian phát hành`,
  text7: `Tổng số tiền đã phát hành`,
  text8: `Số tiền phát hành`,

  remaining: `Nhiệm vụ còn lại hôm nay:`,
  Claim: `Hạn nhận phần thưởng:`,
  Submission: `Hạn nộp:`,
  Completion: `Thời gian hoàn thành:`,
  Complete: `Hoàn thành`,
  error: `Kiểm tra nhiệm vụ thất bại, cần nộp lại!`,
  warning3: `Khi nộp nhiệm vụ, nội dung trong hình phải kèm theo ngày tháng hôm đó`,
  sample: `Ảnh mẫu`,
  Copywriting: `Văn bản quảng cáo`,
  Claim2: `Nhận nhiệm vụ`,
  SubmitTask: `Nộp nhiệm vụ`,
  Waiting: `Đang chờ kiểm tra`,
  Resubmit: `Nộp lại`,
  resubmit: `, vui lòng nộp lại!`,
  Expand: `Mở rộng`,
  Recover: `Khôi phục`,
  Deadline: `Hạn chót:`,
  text9: `1. Nội dung văn bản trong hình phải khớp với văn bản quảng cáo`,
  text10: `2. Nội dung văn bản trong hình phải kèm theo ngày tháng hôm đó`,
  success3: `Tải xuống thành công`,
  error2: `Tải xuống thất bại`,
  success4: `Sao chép thành công`,
  warning4: `Vui lòng tải lên ảnh chụp màn hình đã hoàn thành`,
  success5: `Tải lên thành công`,

  emaillogin: `Đăng nhập bằng email`,
  warning5: `Tài khoản chưa đăng ký sẽ tự động đăng ký và đăng nhập`,
  text11: `Nhận mã xác thực`,
  Sign: `Đăng nhập ngay`,
  text12: `Đăng nhập đồng nghĩa với việc bạn đồng ý với`,
  Privacy: `Chính sách quyền riêng tư`,
  Agreement: `Thoả thuận người dùng`,
  warning6: `Vui lòng nhập địa chỉ email của bạn`,
  warning7: `Vui lòng nhập đúng địa chỉ email`,
  warning8: `Mã xác thực không thể để trống`,
  warning9: `Vui lòng nhập địa chỉ email của bạn`,
  retrieve: `Khôi phục`,
  Payment: `Xác nhận thanh toán`,
  InformationFilling: `Điền Thông Tin`,
  BalanceCombinationPayment: `Thanh Toán Kết Hợp Số Dư`,
  AvailableBalance: `Số Dư Khả Dụng:`,
  will: `sẽ gửi mã xác minh đến`,
  setCode: `gửi mã xác minh`,
  BalanceDeduction: `Sau khi khấu trừ số dư, vẫn cần thanh toán`,
  StarClusterLevel: 'Mức độ cụm sao',
  PersonalPerformance: 'Hiệu suất cá nhân',
  StarClusterPerformance: 'Hiệu suất cụm sao',
  memberNumber: 'Số lượng thành viên',
  NumberStar: 'Số lượng thành viên sao',
  NumberDirectly: 'Số lượng thành viên được mời trực tiếp',
  activeMembers: 'Số lượng thành viên hoạt động',

  title_1: `Đăng nhập`,
  title_2: `Trang chủ`,
  title_3: `Ví của tôi`,
  title_4: `Thẻ Ngôi Sao của tôi`,
  title_5: `Gây quỹ bản quyền`,
  title_6: `Cộng đồng của tôi`,
  title_7: `Về chúng tôi`,
  title_8: `Phúc lợi cộng đồng`,
  title_9: `Chuyển đổi tài khoản`,
  title_10: `Đăng xuất`,
  title_11: 'Đổi mã đổi',
  title_13: 'Trò chuyện IM',
  title_12: `Thị Trường Giao Dịch`,

  interests: `Quyền lợi Thẻ Ngôi Sao`,
  log_fir: `Vui lòng đăng nhập trước`,
  name7: `Xem miễn phí`,
  name8: `Nhiệm vụ hàng ngày`,
  name9: `Hệ thống nhóm ngôi sao`,
  name10: `Nhiều quyền lợi khác`,
  describe5: `Miễn phí VIP đang chờ bạn`,
  describe6: `Nhiệm vụ hàng tỷ`,
  describe7: `Mời bạn bè, chia sẻ phần thưởng`,
  describe8: `Chờ bạn khám phá`,

  Invite: `Mời bạn bè`,
  invitation: `Mã mời của tôi`,
  directly: `Mời bạn bè trực tiếp`,
  indirect: `Bạn bè gián tiếp`,
  Copy_link: `Sao chép liên kết mời`,
  Fill: `Nhập mã mời`,
  inv_code: `Mã mời:`,
  Replicating: `Sao chép thành công`,
  error3: `Sao chép thất bại`,
  error4: `Dán thất bại:`,

  Apple_login: `Đăng nhập bằng Apple`,
  Google_login: `Đăng nhập bằng Google`,
  Email_login: `Đăng nhập bằng email`,

  Commission: `Hoa hồng:`,
  nolevel: `Hiện không có cấp độ nào khả dụng`,
  community: `Số lượng cộng đồng`,
  Community1: `Trực tiếp thúc đẩy hiệu suất`,
  promoted: `Số người quảng bá hôm nay`,
  Promote: `Phần thưởng quảng bá bản quyền`,
  balance2: `Số dư hiện tại`,
  Withdraw: `Rút tiền`,
  Transfer: `Chuyển khoản`,
  Interpersonal: `Giới thiệu bạn bè`,
  mixpayTips: `Số dư được ưu tiên sử dụng, thanh toán sẽ được thực hiện bằng số dư là:`,
  noBalanceL: `Số dư không đủ`,

  days: `ngày`,
  Selected: `Đã chọn`,
  Select: `Chọn`,
  paid: `Số tiền thanh toán`,
  gopay: `Thanh toán ngay`,
  warning10: `Vui lòng chọn phương thức thanh toán trước`,

  progress: `Đang tiến hành thanh toán`,
  cancel: `Hủy bỏ`,
  confirm: `Xác nhận`,
  Paid: `Đã thanh toán`,
  warning11: `Đơn hàng của bạn chưa thanh toán thành công`,

  Remaining: `Số lần còn lại trong tháng:`,
  Exchange: `Danh sách đổi`,
  Redemption: `Mã đổi`,
  Duration: `Thời gian`,
  Period2: `Thời hạn`,
  State: `Trạng thái`,
  Function: `Chức năng`,
  nouse: `Chưa sử dụng`,
  Used: `Đã sử dụng`,
  Copy: `Sao chép`,

  Wallet2: `Lịch sử biến động ví`,
  Variable: `Số tiền biến động (${window.currency})`,
  OrderID: `Mã đơn hàng`,
  Balance3: `Rút số dư`,
  Balance5: `Chuyển khoản`,
  information: `Điền thông tin`,
  Amount4: `Số tiền rút`,
  Balance6: `Số tiền chuyển khoản`,
  Balance4: `Số dư:`,
  nomoney: `Không đủ số dư`,
  crowdfunding: `Tham gia gây quỹ`,

  title_name: `Thoả thuận người dùng`,
  t1: `Thoả thuận này có hiệu lực từ ngày 15 tháng 3 năm 2023 và được cập nhật lần cuối vào ngày 15 tháng 3 năm 2023.`,
  t2: `Công ty Công nghệ ${window.region}${window.company} là chủ sở hữu bản quyền đã đăng ký của phần mềm này và có quyền sở hữu bản quyền của phần mềm này theo luật định. Thỏa thuận người dùng này (sau đây gọi là "Thỏa thuận này") là một hợp đồng hiệu lực giữa Công ty Công nghệ ${window.company} (sau đây gọi là "${window.company} Công nghệ", "chúng tôi") và người dùng (còn gọi là "bạn") về việc cài đặt và sử dụng sản phẩm phần mềm khách hàng "Juyouli" (sau đây gọi là "phần mềm này"). Bằng cách nhấp vào đồng ý với thỏa thuận này hoặc bằng cách chọn chấp nhận thỏa thuận này theo bất kỳ cách nào khác, bạn đã đạt được thỏa thuận với ${window.company} Công nghệ và đồng ý chấp nhận tất cả các điều khoản dưới đây của thỏa thuận này. Phần mềm này được ${window.company} Công nghệ điều hành và cung cấp dịch vụ cho bạn, có quyền và nghĩa vụ pháp lý và chịu trách nhiệm pháp lý.`,
  t3: `Nếu thỏa thuận này có thay đổi, phần mềm sẽ thông báo về việc sửa đổi trên trang phần mềm. Một khi các điều khoản sử dụng đã sửa đổi xuất hiện trên trang thông báo, các điều khoản này sẽ thay thế thỏa thuận ban đầu. Bạn nên đăng nhập vào phần mềm này thường xuyên để kiểm tra các điều khoản mới nhất. Trước khi sử dụng các dịch vụ do phần mềm này cung cấp, bạn nên đọc kỹ thỏa thuận này. Nếu bạn không đồng ý với thỏa thuận đã sửa đổi, bạn có thể tự nguyện hủy bỏ dịch vụ do phần mềm này cung cấp. Nếu không, việc bạn sử dụng sẽ được coi là bạn đã chấp nhận thỏa thuận này và tất cả các nội dung sửa đổi tiếp theo.`,
  t4: `1. Chúng tôi thu thập và sử dụng thông tin cá nhân của bạn như thế nào`,
  t5: `Thông tin cá nhân là các thông tin được ghi lại dưới dạng điện tử hoặc các hình thức khác, có thể được sử dụng để nhận dạng cá nhân cụ thể hoặc phản ánh hoạt động của cá nhân cụ thể đó.`,
  t6: `${window.company} Công nghệ và chủ sở hữu bản quyền đã đăng ký của sản phẩm này sẽ chỉ thu thập và sử dụng thông tin cá nhân của bạn theo các mục đích, phương thức và phạm vi đã được mô tả trong chính sách này khi cung cấp dịch vụ cho bạn:`,
  t7: `1. Chức năng ứng dụng 1: Trở thành người dùng đã đăng ký`,
  t8: `Khi đăng ký trở thành người dùng đã đăng ký của sản phẩm này, bạn cần cung cấp các thông tin sau: thông tin thiết bị của bạn.`,
  t9: `Các thông tin bạn cung cấp cho phép chúng tôi tiếp tục cung cấp dịch vụ cho bạn. Khi bạn hủy tài khoản, chúng tôi sẽ ngừng sử dụng và xóa các thông tin trên.`,
  t10: `2. Chức năng ứng dụng 2: Hiển thị nhật ký hoạt động trong ứng dụng`,
  t11: `Khi bạn sử dụng dịch vụ của chúng tôi, sản phẩm này sẽ ghi lại nhật ký hoạt động của bạn, bao gồm các bản ghi duyệt và nhấp chuột, thêm chương trình truyền hình, xem chương trình truyền hình và tham gia các hoạt động. Các thông tin bạn cung cấp cho phép chúng tôi tiếp tục cung cấp dịch vụ cho bạn. Khi bạn hủy tài khoản, chúng tôi sẽ ngừng sử dụng và xóa các thông tin trên.`,
  t12: `3. Chức năng ứng dụng ba: Đăng ký và thanh toán`,
  t13: `Khi bạn sử dụng sản phẩm này, các bản ghi tạo ra khi đăng ký thành viên VIP và thanh toán dịch vụ xem phim sẽ được lưu trữ trên máy chủ của chúng tôi. Các thông tin trên là cần thiết cho chức năng sản phẩm. Nếu bạn không đồng ý cho chúng tôi ghi lại các thông tin trên, chúng tôi sẽ không thể cung cấp dịch vụ mua phim cho bạn.`,
  t14: `4. Chức năng ứng dụng bốn: Dịch vụ an ninh`,
  t15: `Để cung cấp cho bạn dịch vụ an toàn và ổn định, bảo vệ tài khoản và tài sản của bạn, ngăn chặn việc đăng nhập giả mạo, để bạn có thể sử dụng bình thường các chức năng và dịch vụ của sản phẩm này, khi bạn sử dụng sản phẩm hoặc dịch vụ của chúng tôi, chúng tôi có thể tự động ghi lại một số thông tin, bao gồm: thông tin thiết bị hoặc phần mềm: thông tin cấu hình cung cấp bởi thiết bị di động, trình duyệt web hoặc chương trình khác được sử dụng để truy cập dịch vụ của chúng tôi, địa chỉ IP của bạn, thông tin phiên bản phần mềm của thiết bị di động của bạn, mã nhận dạng thiết bị (IMEI, địa chỉ MAC), mã nhận dạng duy nhất của thiết bị (Android ID, OAID) và số sê-ri thiết bị. Nếu bạn không đồng ý cho chúng tôi ghi lại các thông tin trên, bạn có thể không hoàn thành quy trình kiểm soát rủi ro và sử dụng sản phẩm của chúng tôi. Chúng tôi cũng có thể tự động ghi lại việc bạn đã cài đặt ứng dụng Alipay hay chưa. Nếu bạn không đồng ý cho chúng tôi ghi lại các thông tin trên, việc sử dụng sản phẩm của bạn sẽ không bị ảnh hưởng.`,
  t16: `5. Chức năng ứng dụng năm: Kích hoạt quyền`,
  t17: `Chúng tôi cam kết không vượt quá phạm vi hoặc tần suất thu thập thông tin cá nhân của bạn và sẽ không bao giờ thu thập hoặc sử dụng các thông tin không cần thiết hoặc không có ngữ cảnh ứng dụng hợp lý trong dịch vụ sản phẩm này.`,
  t18: `Khi sử dụng sản phẩm này, để cung cấp dịch vụ cho bạn, sản phẩm này có thể yêu cầu các quyền sau của thiết bị đầu cuối trong một số tình huống sử dụng ứng dụng. Tất nhiên, bạn có thể từ chối kích hoạt các quyền sau hoặc tắt các quyền sau trong cài đặt điện thoại. Việc tắt các quyền sau sẽ không ảnh hưởng đến việc sử dụng các chức năng khác của sản phẩm này:`,
  t19: `Truy cập điện thoại: Bằng cách nhận quyền trạng thái điện thoại của bạn, chúng tôi ghi lại mã nhận dạng thiết bị của bạn (tức là mã IMEI hoặc IMSI) để xử lý ẩn danh, được sử dụng để hiển thị quảng cáo và xác thực bảo mật;`,
  t20: `Truy cập bộ nhớ cục bộ: Bằng cách kích hoạt quyền lưu trữ cục bộ, bạn có thể tải xuống các tập phim trong sản phẩm này vào bộ nhớ điện thoại của bạn;`,
  t21: `Truy cập album: Bạn có thể chọn trực tiếp ảnh hoặc hình ảnh từ điện thoại để tải lên hoặc cung cấp chứng minh khi giao tiếp với chăm sóc khách hàng;`,
  t22: `Truy cập nội dung và tệp phương tiện trên thiết bị: Được sử dụng để lưu trữ đọc và ghi hình bìa tập phim và hình ảnh sự kiện, cải thiện trải nghiệm sử dụng ứng dụng.`,
  t23: `Kích hoạt dữ liệu không dây: Bạn có thể kết nối mạng không dây hoặc mạng di động để thực hiện các chức năng mạng cần thiết của sản phẩm này;`,
  t24: `Truy cập danh sách cài đặt: Bằng cách nhận danh sách cài đặt của bạn, chúng tôi có thể xác nhận liệu bạn đã cài đặt sản phẩm mà chúng tôi quảng bá hay chưa, để kịp thời phân phối các phần thưởng tương ứng cho bạn.`,
  t25: `Mở micrô và nhận diện giọng nói: Bạn có thể điều khiển sản phẩm này bằng chức năng điều khiển giọng nói và đọc giọng nói;`,
  t26: `Sử dụng bảng tạm: Bạn có thể sao chép và dán số điện thoại chăm sóc khách hàng hoặc chọn không sử dụng quyền bảng tạm.`,
  t27: `Truy cập quyền mạng của thiết bị, được sử dụng để sử dụng từ điển, bách khoa toàn thư và đọc giọng nói;`,
  t28: `Nhận quyền vị trí tổng quát: Khi duyệt một số quảng cáo, nhận quyền vị trí tổng quát;`,
  t29: `Truy cập vị trí tìm kiếm: Khi duyệt một số quảng cáo, sẽ nhận quyền vị trí tìm kiếm;`,
  t30: `Sử dụng máy ảnh: Khi cung cấp phản hồi của người dùng, có thể nhận được quyền sử dụng máy ảnh.`,
  t31: `2. Chúng tôi sử dụng Cookie và các công nghệ tương tự như thế nào`,
  t32: `Cookie và các công nghệ tương tự được sử dụng rộng rãi trên Internet. Để đảm bảo hoạt động bình thường của nền tảng này và cung cấp trải nghiệm truy cập dễ dàng hơn cho bạn, chúng tôi có thể lưu trữ các tệp dữ liệu nhỏ gọi là Cookie trên thiết bị di động của bạn. Cookie thường chứa các định danh, tên trang web và một số số và ký tự. Nhờ Cookie, chúng tôi sẽ ghi lại thông tin đăng nhập và hoạt động của bạn trong sản phẩm này, để chúng tôi có thể lưu trữ thói quen sử dụng của bạn và giúp xác định trạng thái bảo mật tài khoản của bạn, cung cấp trải nghiệm người dùng và dịch vụ dễ dàng hơn cho bạn.`,
  t33: `Chúng tôi sẽ không sử dụng Cookie cho bất kỳ mục đích nào khác ngoài những mục đích đã nêu trong chính sách này. Bạn có thể quản lý hoặc tắt Cookie theo sở thích của mình. Để biết thêm thông tin, vui lòng tham khảo AboutCookies.org. Nếu tắt Cookie, bạn có thể không tận hưởng được trải nghiệm dịch vụ tốt nhất và các nội dung chúng tôi đề xuất có thể không liên quan đến sở thích của bạn.`,
  t34: `3. Chúng tôi chia sẻ, chuyển nhượng và công khai thông tin cá nhân của bạn như thế nào`,
  t35: `(1) Chia sẻ`,
  t36: `Trừ khi có các tình huống sau, chúng tôi sẽ không chia sẻ thông tin cá nhân của bạn với bất kỳ công ty, tổ chức hoặc cá nhân nào ngoài ${window.company} Công nghệ và chủ sở hữu bản quyền đã đăng ký của sản phẩm này và các công ty liên kết của họ:`,
  t37: `1. Chia sẻ sau khi có sự đồng ý rõ ràng: Sau khi có sự đồng ý rõ ràng của bạn, chúng tôi sẽ chia sẻ thông tin cá nhân của bạn với các bên khác.`,
  t38: `2. Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với công chúng theo yêu cầu bắt buộc của luật pháp, quy định hoặc cơ quan chính phủ.`,
  t39: `3. Chia sẻ với đối tác ủy quyền: Một số dịch vụ của chúng tôi sẽ được cung cấp bởi đối tác ủy quyền, chỉ nhằm mục đích được mô tả trong chính sách này. Chúng tôi có thể chia sẻ một số thông tin cá nhân của bạn với các đối tác của chúng tôi để cung cấp dịch vụ khách hàng và trải nghiệm người dùng tốt hơn. Chúng tôi sẽ chỉ chia sẻ thông tin cá nhân cần thiết cho dịch vụ và sẽ không chia sẻ thông tin cá nhân ngoài mục đích hợp pháp, chính đáng, cần thiết, cụ thể và rõ ràng. Các đối tác của chúng tôi không được phép sử dụng thông tin cá nhân được chia sẻ cho bất kỳ mục đích nào khác.`,
  t40: `Hiện tại, các đối tác ủy quyền của chúng tôi bao gồm các loại sau:`,
  t41: `1) Nhà cung cấp, nhà cung cấp dịch vụ, đại lý thứ ba và các đối tác khác. Chúng tôi gửi thông tin cho các nhà cung cấp, nhà cung cấp dịch vụ, đại lý thứ ba và các đối tác khác trên toàn thế giới hỗ trợ hoạt động kinh doanh của chúng tôi, bao gồm cung cấp dịch vụ hạ tầng công nghệ, phân tích việc sử dụng dịch vụ của chúng tôi, đo lường hiệu quả của nó, cung cấp dịch vụ khách hàng, tạo điều kiện thanh toán hoặc thực hiện nghiên cứu học thuật và khảo sát. Trong trường hợp bạn đồng ý rõ ràng trước đó, chúng tôi có thể chia sẻ hồ sơ tiêu thụ của bạn, hồ sơ đăng ký, hồ sơ nạp tiền, hồ sơ đơn hàng, hồ sơ xem phim đã bị xóa và hồ sơ tiêu thụ trong các hoạt động với các đại lý thứ ba sau khi áp dụng phương pháp mã hóa và ẩn danh. Nếu bạn không đồng ý cho chúng tôi chia sẻ các thông tin trên, điều này sẽ không ảnh hưởng đến việc sử dụng sản phẩm này của bạn.`,
  t42: `2) Nhà cung cấp dịch vụ phân tích dữ liệu: Để cung cấp dịch vụ tốt hơn cho bạn, chúng tôi hợp tác với các nhà cung cấp dịch vụ dữ liệu thứ ba bao gồm Youmeng+ để dự đoán đặc điểm và sở thích của bạn. Để đảm bảo an toàn cho dữ liệu của bạn, dự đoán này sẽ được cung cấp dưới dạng điểm số chỉ số và phân tích dữ liệu sẽ được thực hiện dưới các biện pháp bảo mật xóa dấu nhận diện nghiêm ngặt để tránh tiết lộ thông tin cá nhân của bạn.`,
  t43: `3) Đối tác dịch vụ quảng cáo và phân tích được ủy quyền. Trừ khi chúng tôi có được sự cho phép của bạn, chúng tôi sẽ không chia sẻ thông tin nhận diện cá nhân của bạn (chỉ những thông tin có thể nhận diện bạn và liên hệ với bạn hoặc xác định bạn) với các đối tác dịch vụ quảng cáo và phân tích. Tuy nhiên, chúng tôi có thể chia sẻ thông tin cá nhân của bạn dưới dạng ẩn danh với các đối tác dịch vụ quảng cáo và phân tích được ủy quyền, bao gồm thông tin nhận diện mạng (địa chỉ IP), số phiên bản phần mềm, tên gói phần mềm, danh sách cài đặt phần mềm, thông tin kết nối thiết bị (nhà cung cấp dịch vụ, môi trường mạng), thông tin thuộc tính thiết bị (nhà sản xuất thiết bị, mẫu thiết bị, phiên bản hệ điều hành, IMEI, OAID, IMSI, cảm biến, số sê-ri thiết bị) để giúp họ nâng cao tỷ lệ tiếp cận quảng cáo mà không nhận diện cá nhân của bạn.`,
  t44: `Quyền hạn cần thiết cho dịch vụ SDK bên thứ ba. Các sản phẩm và dịch vụ của chúng tôi có thể bao gồm các sản phẩm và dịch vụ của bên thứ ba và các liên kết đến các trang web của bên thứ ba. Khi bạn sử dụng các sản phẩm hoặc dịch vụ này, thông tin của bạn cũng có thể được thu thập. Sau khi người dùng đồng ý cấp quyền, thông tin cá nhân sẽ được gửi đến bên thứ ba. Khi danh tính của bên thứ ba thay đổi, chúng tôi sẽ yêu cầu người dùng cấp quyền lại thông qua cửa sổ bật lên, và chúng tôi sẽ chia sẻ thông tin cá nhân với bên thứ ba.`,
  t45: `(2) Chuyển nhượng`,
  t46: `Trừ khi có các tình huống sau, chúng tôi sẽ không chuyển nhượng thông tin cá nhân của bạn cho bất kỳ công ty, tổ chức hoặc cá nhân nào:`,
  t47: `1. Sau khi có sự đồng ý rõ ràng của bạn, chúng tôi sẽ chuyển nhượng thông tin cá nhân của bạn cho các bên khác;`,
  t48: `2. Trong trường hợp hợp nhất, mua lại hoặc thanh lý phá sản, nếu việc chuyển nhượng thông tin cá nhân liên quan, chúng tôi sẽ yêu cầu công ty hoặc tổ chức mới giữ thông tin cá nhân của bạn tiếp tục tuân theo chính sách bảo mật này. Nếu không, chúng tôi sẽ yêu cầu công ty hoặc tổ chức đó xin sự đồng ý và sự ủy quyền của bạn lại.`,
  t49: `(3) Công khai tiết lộ`,
  t50: `Chúng tôi chỉ công khai tiết lộ thông tin cá nhân của bạn trong các trường hợp sau:`,
  t51: `1. Sau khi có sự đồng ý rõ ràng của bạn;`,
  t52: `2. Công khai theo yêu cầu của pháp luật: Chúng tôi có thể công khai tiết lộ thông tin cá nhân của bạn theo yêu cầu bắt buộc của pháp luật, quy trình, tố tụng hoặc cơ quan chính phủ.`,
  t53: `(4) Các loại thông tin cá nhân có thể được chia sẻ bao gồm mã nhận dạng thiết bị và thông tin phiên bản sản phẩm; các loại thông tin cá nhân có thể được công khai tiết lộ bao gồm xem phim truyền hình và thời gian xem phim.`,
  t54: `Chúng tôi hiểu trách nhiệm pháp lý liên quan đến việc chia sẻ, chuyển nhượng và công khai thông tin cá nhân với công chúng và sẽ thực hiện các biện pháp hợp lý để đảm bảo an toàn thông tin cá nhân.`,
  t55: `4. Chúng tôi bảo vệ và lưu trữ thông tin cá nhân của bạn như thế nào`,
  t56: `(1) Các biện pháp bảo vệ công nghệ`,
  t57: `Chúng tôi rất quan tâm đến an toàn thông tin cá nhân của bạn và sẽ nỗ lực thực hiện các biện pháp bảo mật hợp lý theo tiêu chuẩn ngành để bảo vệ thông tin của bạn khỏi rò rỉ, hư hại hoặc mất mát, bao gồm nhưng không giới hạn ở SSL, mã hóa lưu trữ thông tin cá nhân và kiểm soát truy cập trung tâm dữ liệu. Chúng tôi sẽ sử dụng công nghệ mã hóa để tăng cường bảo mật thông tin cá nhân; chúng tôi sẽ sử dụng các cơ chế bảo vệ tin cậy để ngăn chặn các cuộc tấn công độc hại đối với thông tin cá nhân; chúng tôi sẽ triển khai cơ chế kiểm soát truy cập, đảm bảo rằng chỉ những người được ủy quyền mới có thể truy cập thông tin cá nhân.`,
  t58: `(2) Hệ thống quản lý an toàn`,
  t59: `Chúng tôi đã xây dựng một hệ thống quản lý an toàn dữ liệu hàng đầu trong ngành, tập trung vào chu kỳ sống dữ liệu, từ xây dựng tổ chức, thiết kế hệ thống, quản lý nhân sự, công nghệ sản phẩm và đánh giá tác động bảo mật thông tin cá nhân.`,
  t60: `Chúng tôi cũng thực hiện các biện pháp quản lý nghiêm ngặt đối với nhân viên hoặc nhân viên ngoài được ủy quyền có thể tiếp xúc với thông tin của bạn, bao gồm nhưng không giới hạn ở kiểm soát quyền hạn khác nhau theo vị trí của họ, ký kết thỏa thuận bảo mật và giám sát hoạt động của họ.`,
  t61: `(3) Bảo vệ tài khoản`,
  t62: `Tài khoản của bạn có tính năng bảo vệ an toàn, vui lòng bảo quản kỹ thông tin tài khoản và mật khẩu của bạn. Nếu bạn phát hiện tài khoản của bạn bị đánh cắp hoặc thông tin cá nhân bị rò rỉ, vui lòng liên hệ với chúng tôi kịp thời để chúng tôi có thể thực hiện các biện pháp phù hợp.`,
  t63: `(4) Xử lý sự cố bảo mật thông tin`,
  t64: `Mặc dù đã thực hiện các biện pháp bảo mật trên, nhưng xin hiểu rằng không có biện pháp nào trên mạng thông tin là hoàn toàn an toàn.`,
  t65: `Nếu xảy ra sự cố an toàn thông tin cá nhân, chúng tôi sẽ thông báo cho bạn theo yêu cầu của pháp luật: tình trạng cơ bản của sự cố và các biện pháp xử lý đã hoặc sẽ được thực hiện, các biện pháp bạn có thể tự mình thực hiện để ngăn ngừa và giảm thiểu rủi ro, và các biện pháp khắc phục mà chúng tôi cung cấp cho bạn. Chúng tôi sẽ thông báo cho bạn về tình hình sự cố qua điện thoại hoặc thông báo. Nếu không thể thông báo từng cá nhân, chúng tôi sẽ thực hiện các biện pháp hợp lý và hiệu quả để công bố thông báo. Đồng thời, chúng tôi cũng sẽ báo cáo tình hình xử lý sự cố bảo mật thông tin cá nhân theo yêu cầu của cơ quan quản lý.`,
  t66: `(5) Thời gian lưu trữ`,
  t67: `Chúng tôi chỉ lưu trữ thông tin cá nhân của bạn trong lãnh thổ Trung Quốc. Chúng tôi sẽ thực hiện tất cả các biện pháp hợp lý để đảm bảo không thu thập các thông tin cá nhân không liên quan. Chúng tôi chỉ lưu giữ thông tin cá nhân của bạn trong thời gian ngắn nhất theo quy định pháp luật để đạt được mục đích được quy định trong chính sách này, trừ khi luật pháp yêu cầu hoặc cho phép gia hạn thời gian lưu giữ.`,
  t68: `Sau khi vượt quá thời hạn lưu giữ, chúng tôi sẽ xóa thông tin cá nhân của bạn theo yêu cầu pháp luật hoặc thực hiện các biện pháp ẩn danh.`,
  t69: `5. Quyền của bạn`,
  t70: `Theo luật pháp, quy định và tiêu chuẩn có liên quan của Trung Quốc, chúng tôi bảo đảm bạn có các quyền sau đối với thông tin cá nhân của mình:`,
  t71: `(1) Truy cập thông tin cá nhân của bạn`,
  t72: `Bạn có quyền truy cập thông tin cá nhân của mình thông qua trung tâm người dùng của mình. Vui lòng tham khảo (VI) chúng tôi đáp ứng yêu cầu của bạn như thế nào, trừ khi có quy định pháp luật khác.`,
  t73: `(2) Sửa chữa thông tin cá nhân của bạn`,
  t74: `Khi bạn phát hiện thông tin cá nhân của mình mà chúng tôi xử lý có sai sót, bạn có quyền yêu cầu chúng tôi sửa chữa. Vui lòng xem cách sửa chữa thông tin cá nhân tại (VI) chúng tôi đáp ứng yêu cầu của bạn như thế nào.`,
  t75: `(3) Xóa thông tin cá nhân của bạn. Trong các trường hợp sau, bạn có thể yêu cầu chúng tôi xóa thông tin cá nhân của bạn:`,
  t76: `1. Chúng tôi xử lý thông tin cá nhân vi phạm pháp luật;`,
  t77: `2. Chúng tôi thu thập và sử dụng thông tin cá nhân của bạn mà không có sự đồng ý của bạn;`,
  t78: `3. Chúng tôi xử lý thông tin cá nhân vi phạm thỏa thuận với bạn;`,
  t79: `4. Bạn không còn sử dụng sản phẩm hoặc dịch vụ của chúng tôi, hoặc bạn đã hủy tài khoản của mình;`,
  t80: `5. Chúng tôi sẽ không cung cấp sản phẩm hoặc dịch vụ cho bạn nữa.`,
  t81: `Cách xóa thông tin cá nhân có thể được tìm thấy trong (VI) chúng tôi đáp ứng yêu cầu của bạn như thế nào. Nếu chúng tôi quyết định tuân thủ yêu cầu của bạn, chúng tôi cũng sẽ thông báo cho các tổ chức đã lấy thông tin cá nhân của bạn từ chúng tôi để họ xóa ngay, trừ khi có quy định pháp luật khác hoặc bạn ủy quyền độc lập.`,
  t82: `Khi bạn xóa thông tin từ dịch vụ của chúng tôi, chúng tôi có thể không ngay lập tức xóa thông tin tương ứng trong hệ thống sao lưu, nhưng chúng tôi sẽ xóa thông tin này trong quá trình cập nhật sao lưu.`,
  t83: `(4) Thu hồi sự đồng ý và tắt chức năng sử dụng`,
  t84: `Bạn có thể thay đổi phạm vi ủy quyền thu thập thông tin cá nhân của chúng tôi hoặc thu hồi ủy quyền của bạn bằng cách tắt chức năng của thiết bị. Bạn cũng có thể thu hồi tất cả các ủy quyền thu thập thông tin cá nhân của chúng tôi bằng cách hủy tài khoản. Cách thu hồi sự đồng ý và tắt chức năng sử dụng có thể được tìm thấy trong (VI) chúng tôi đáp ứng yêu cầu của bạn như thế nào.`,
  t85: `(5) Quản lý đề xuất cá nhân hóa`,
  t86: `1. Quản lý đề xuất quảng cáo cá nhân hóa`,
  t87: `Quảng cáo cá nhân hóa là một công nghệ hiển thị quảng cáo, quảng cáo viên dựa trên sở thích của bạn trong ứng dụng để đề xuất thông minh. Nếu bạn không muốn nhận đề xuất quảng cáo cá nhân hóa, bạn có thể chọn tắt tính năng đề xuất quảng cáo cá nhân hóa trong [Của tôi] - [Cài đặt hệ thống] - [Đề xuất quảng cáo cá nhân hóa]. Sau khi tắt, sự liên quan của quảng cáo bạn nhìn thấy sẽ giảm.`,
  t88: `2. Quản lý đề xuất nội dung cá nhân hóa`,
  t89: `Để đảm bảo bạn có thể xem các nội dung bạn quan tâm, chúng tôi cung cấp tính năng đề xuất nội dung cá nhân hóa. Nếu bạn không muốn nhận đề xuất nội dung cá nhân hóa, bạn có thể tắt tính năng đề xuất nội dung cá nhân hóa bằng cách sử dụng [Của tôi] - [Cài đặt hệ thống] - [Đề xuất nội dung cá nhân hóa]. Sau khi tắt, trang chủ sẽ không hiển thị kênh đề xuất. Chuyển đến kênh đã chọn sẽ giảm độ liên quan của nội dung.`,
  t90: `(6) Hủy tài khoản`,
  t91: `Bạn có thể hủy tài khoản của mình tại [Của tôi] - [Cài đặt hệ thống] - [Hủy tài khoản]. Bạn có thể hủy tài khoản đã đăng ký của mình bất cứ lúc nào, vui lòng tham khảo "Thông báo hủy" để biết chi tiết.`,
  t92: `Sau khi hủy tài khoản, chúng tôi sẽ ngừng cung cấp sản phẩm hoặc dịch vụ cho bạn và xóa thông tin cá nhân của bạn, trừ khi có quy định pháp luật khác.`,
  t93: `Trong các trường hợp sau, theo yêu cầu của pháp luật, chúng tôi sẽ không thể đáp ứng yêu cầu xóa thông tin cá nhân của bạn:`,
  t94: `1. Liên quan trực tiếp đến an ninh quốc gia, an ninh quốc phòng;`,
  t95: `2. Liên quan trực tiếp đến an ninh công cộng, y tế công cộng, lợi ích công cộng quan trọng;`,
  t96: `3. Liên quan trực tiếp đến điều tra hình sự, truy tố, xét xử và thi hành án;`,
  t97: `4. Có bằng chứng đủ chứng minh bạn có ác ý hoặc lạm dụng quyền lực;`,
  t98: `5. Đáp ứng yêu cầu của bạn sẽ gây thiệt hại nghiêm trọng đến lợi ích hợp pháp của bạn hoặc của tổ chức hoặc cá nhân khác;`,
  t99: `6. Liên quan đến bí mật thương mại.`,
  t100: `(7) Đáp ứng yêu cầu của bạn`,
  t101: `Bạn có thể chọn tắt quyền trong chức năng "Cài đặt" trên điện thoại.`,
  t102: `Bạn cũng có thể gọi điện thoại đến chăm sóc khách hàng bằng cách nhấp vào "Của tôi" - "Về chúng tôi" - "Liên hệ chăm sóc khách hàng" để thực hiện cuộc gọi điện thoại, sau đó liên hệ với chăm sóc khách hàng để yêu cầu truy vấn thông tin cá nhân, sửa chữa thông tin cá nhân, xóa thông tin cá nhân, hủy tài khoản người dùng và thu hồi sự đồng ý đã cấp và các thao tác người dùng khác. Để đảm bảo an toàn, bạn có thể cần cung cấp yêu cầu bằng văn bản hoặc các phương pháp khác để chứng minh danh tính của mình. Trước khi xử lý yêu cầu của bạn, chúng tôi có thể yêu cầu bạn xác minh danh tính.`,
  t103: `Chúng tôi sẽ cung cấp phản hồi trong vòng mười lăm ngày làm việc. Nếu bạn không hài lòng, bạn cũng có thể khiếu nại thông qua chăm sóc khách hàng.`,
  t104: `6. Chúng tôi xử lý thông tin cá nhân của trẻ em như thế nào`,
  t105: `Các sản phẩm, trang web và dịch vụ của chúng tôi chủ yếu dành cho người lớn. Chúng tôi rất coi trọng việc bảo vệ thông tin cá nhân của trẻ em và trẻ vị thành niên. Nếu bạn là trẻ vị thành niên dưới 18 tuổi, trước khi sử dụng các dịch vụ liên quan trên nền tảng này, bạn nên đọc và đồng ý với chính sách bảo mật này dưới sự giám sát và hướng dẫn của cha mẹ hoặc người giám hộ khác, và sử dụng dịch vụ của chúng tôi hoặc cung cấp thông tin cho chúng tôi với sự đồng ý của người giám hộ.`,
  t106: `Đối với các trường hợp chúng tôi thu thập thông tin cá nhân của trẻ vị thành niên dưới sự đồng ý của cha mẹ hoặc người giám hộ, chúng tôi sẽ chỉ sử dụng, chia sẻ, chuyển nhượng hoặc tiết lộ thông tin đó khi được phép bởi luật pháp và quy định, hoặc khi cần thiết để bảo vệ trẻ vị thành niên. Nếu chúng tôi phát hiện đã thu thập thông tin cá nhân của trẻ vị thành niên mà không có sự đồng ý của cha mẹ hoặc người giám hộ, chúng tôi sẽ nhanh chóng xóa các thông tin liên quan.`,
  t107: `7. Chuyển giao thông tin cá nhân của bạn ra quốc tế như thế nào`,
  t108: `Hiện tại, chúng tôi sẽ không truyền tải hoặc lưu trữ thông tin cá nhân của bạn ra khỏi quốc gia. Nếu trong tương lai cần truyền tải hoặc lưu trữ ra quốc tế, chúng tôi sẽ thông báo cho bạn về mục đích của việc xuất thông tin, người nhận, biện pháp bảo mật và rủi ro bảo mật của việc xuất thông tin, và sẽ nhận được sự đồng ý của bạn.`,
  t109: `8. Cập nhật chính sách này như thế nào`,
  t110: `Sau khi cập nhật chính sách bảo mật này, nền tảng sẽ hiển thị chính sách bảo mật cập nhật và nội dung cho bạn khi bạn đăng nhập và cập nhật phiên bản thông qua thông báo, cửa sổ bật lên, thông báo trang web, vv, để bạn có thể kịp thời hiểu được phiên bản mới nhất của chính sách bảo mật này. Chúng tôi sẽ tuân thủ chặt chẽ các quy tắc thu thập và sử dụng thông tin cá nhân mà chúng tôi đã tiết lộ cho bạn và thực hiện các hoạt động xử lý thông tin cá nhân. Nếu mục đích sử dụng thông tin cá nhân thay đổi, chúng tôi sẽ xin sự đồng ý của bạn một lần nữa. Nếu bạn tiếp tục sử dụng dịch vụ của chúng tôi, điều đó có nghĩa là bạn đồng ý chấp nhận nội dung sửa đổi của chính sách này.`,
  t111: `9. Áp dụng chính sách này`,
  t112: `Tất cả các dịch vụ của sản phẩm này đều chịu sự điều chỉnh của chính sách này.`,
  t113: `Khi bạn đăng nhập vào sản phẩm này lần đầu tiên, chúng tôi sẽ nhắc bạn đọc chính sách này và nhận được sự đồng ý của bạn. Trừ khi có thoả thuận khác hoặc yêu cầu pháp lý, chính sách bảo mật này không áp dụng cho các sản phẩm hoặc dịch vụ của bên thứ ba mà công ty công nghệ ${window.company} và chủ sở hữu bản quyền đã đăng ký của sản phẩm này liên kết đến. Việc bạn sử dụng các dịch vụ của bên thứ ba này (bao gồm bất kỳ thông tin cá nhân nào bạn cung cấp cho các bên thứ ba này) sẽ chịu sự điều chỉnh của các điều khoản và điều kiện của các dịch vụ và chính sách bảo mật của các bên thứ ba đó (chứ không phải chính sách bảo mật này). Vui lòng bảo vệ thông tin cá nhân của bạn cẩn thận và chỉ cung cấp cho bên thứ ba khi cần thiết.`,

  p1: `Chính sách bảo mật này (sau đây gọi là "chính sách này") được phát hành và có hiệu lực từ ngày 15 tháng 3 năm 2023, gần đây được sửa đổi vào ngày 10 tháng 4 năm 2023. Chính sách này là một hợp đồng hiệu lực giữa công ty công nghệ ${window.region}${window.company} (sau đây gọi là "${window.company} Công nghệ", "${window.company}" hoặc "chúng tôi") và người dùng (còn gọi là "bạn") về các vấn đề bảo mật liên quan đến sản phẩm khách hàng "Hippo Theater" (sau đây gọi là "sản phẩm này"). Bằng cách nhấp vào đồng ý với thỏa thuận này hoặc bằng cách chọn chấp nhận thỏa thuận này theo bất kỳ cách nào khác, bạn đã đạt được thỏa thuận với công ty công nghệ ${window.company} và đồng ý chấp nhận tất cả các điều khoản dưới đây của chính sách này.`,
  p2: `Công ty công nghệ ${window.region}${window.company} là chủ sở hữu bản quyền đã đăng ký của sản phẩm này và có quyền sở hữu bản quyền của sản phẩm này theo luật định.`,
  p3: `Sản phẩm này do công ty công nghệ ${window.company} và ${window.company} đồng điều hành, cung cấp dịch vụ cho bạn, có quyền và nghĩa vụ pháp lý và chịu trách nhiệm pháp lý.`,
  p4: `Chính sách này sẽ giúp bạn hiểu các nội dung sau:`,
  p5: `1. Chúng tôi thu thập và sử dụng thông tin cá nhân của bạn như thế nào`,
  p6: `2. Chúng tôi sử dụng Cookie và các công nghệ tương tự như thế nào`,
  p7: `3. Chúng tôi chia sẻ, chuyển nhượng và công khai thông tin cá nhân của bạn như thế nào`,
  p8: `4. Chúng tôi bảo vệ thông tin cá nhân của bạn như thế nào`,
  p9: `5. Quyền của bạn`,
  p10: `6. Chúng tôi xử lý thông tin cá nhân của trẻ em như thế nào`,
  p11: `7. Chuyển giao thông tin cá nhân của bạn ra quốc tế như thế nào`,
  p12: `8. Cập nhật chính sách này như thế nào`,
  p13: `9. Áp dụng chính sách này`,
  p14: `10. Giải quyết tranh chấp`,
  p15: `${window.company} Công nghệ và chủ sở hữu bản quyền đã đăng ký của sản phẩm này rất coi trọng việc bảo vệ thông tin cá nhân. Khi người dùng (viết tắt là 'bạn') sử dụng sản phẩm hoặc dịch vụ của chúng tôi, chúng tôi có thể thu thập và sử dụng thông tin liên quan của bạn. Một khi bạn chọn sử dụng sản phẩm hoặc dịch vụ của chúng tôi, điều đó có nghĩa là bạn chấp nhận và đồng ý với các điều khoản hiện có và bất kỳ cập nhật nào có thể được thực hiện. Nếu bạn không đồng ý, bạn nên chấm dứt sử dụng dịch vụ của sản phẩm này. Vui lòng đọc kỹ và hiểu rõ chính sách bảo mật này trước khi sử dụng sản phẩm của chúng tôi.`,
  p16: `1. Vui lòng đọc kỹ và hiểu rõ tất cả các quyền và hạn chế được quy định trong thỏa thuận này. Một khi bạn cài đặt, sao chép, tải xuống, truy cập hoặc sử dụng sản phẩm này theo bất kỳ cách nào khác, sẽ được coi là bạn chấp nhận thỏa thuận này và đồng ý bị ràng buộc bởi các điều khoản của thỏa thuận này. Bạn phải đảm bảo tải xuống hoặc sử dụng sản phẩm phần mềm này từ trang web được chỉ định và nhận từ phương tiện hợp pháp. Nếu không, chúng tôi sẽ không chịu trách nhiệm cho bất kỳ rủi ro và thiệt hại tiềm tàng nào có thể phát sinh. Chúng tôi nhắc bạn rằng, nếu bạn dưới 18 tuổi hoặc không có năng lực hành vi dân sự đầy đủ, vui lòng đọc thỏa thuận này cùng với người giám hộ hợp pháp của bạn và chú ý đặc biệt đến các điều khoản liên quan đến việc sử dụng dịch vụ của người chưa thành niên. Nếu không, trừ các trường hợp được pháp luật quy định rõ ràng, chúng tôi không chịu trách nhiệm cho bất kỳ hậu quả bất lợi nào có thể phát sinh từ việc đăng ký, sử dụng dịch vụ của sản phẩm này hoặc thanh toán nạp tiền. Chúng tôi có quyền chấm dứt thỏa thuận người dùng nếu phát hiện tình huống này.`,
  p17: `2. Tuyên bố quyền`,
  p18: `1. Công ty công nghệ ${window.region}${window.company} là chủ sở hữu bản quyền đã đăng ký của phần mềm này và có quyền sở hữu bản quyền của phần mềm này theo luật định.`,
  p19: `2. Không có sự đồng ý bằng văn bản của ${window.company} Công nghệ và chủ sở hữu bản quyền đã đăng ký của phần mềm này, người dùng không được thực hiện, sử dụng, chuyển nhượng hoặc ủy quyền cho bất kỳ bên thứ ba nào thực hiện, sử dụng, chuyển nhượng bản quyền phần mềm và các quyền sở hữu trí tuệ khác đã nêu trên nhằm mục đích lợi nhuận hoặc phi lợi nhuận. ${window.company} Công nghệ và chủ sở hữu bản quyền đã đăng ký của phần mềm này bảo lưu quyền truy cứu hành vi không được phép đã nêu trên.`,
  p20: `3. Cấm kỹ thuật đảo ngược, giải mã và tháo rời: Người dùng không được thực hiện kỹ thuật đảo ngược, giải mã hoặc tháo rời sản phẩm phần mềm này, không được sửa đổi bất kỳ tài nguyên nào trong tệp chương trình đã biên dịch. Trừ khi được pháp luật quy định rõ ràng, người dùng phải tuân thủ các hạn chế của thỏa thuận này.`,
  p21: `4. Phân tách thành phần: Giấy phép sử dụng sản phẩm phần mềm này được cung cấp dưới dạng toàn bộ và người dùng không được tách từng phần ra sử dụng cho bất kỳ mục đích nào.`,
  p22: `5. Giấy phép riêng: Nếu cần bán hàng, sao chép, phân phối thương mại, bao gồm nhưng không giới hạn ở việc bán hàng phần mềm, cài đặt trước, đóng gói, vv, phải được sự đồng ý bằng văn bản riêng của chủ sở hữu bản quyền phần mềm.`,
  p23: `6. Bảo lưu quyền: Tất cả các quyền khác không được rõ ràng cấp phép trong thỏa thuận này vẫn thuộc về ${window.company} Công nghệ, người dùng phải nhận được sự đồng ý bằng văn bản của ${window.company} Công nghệ khi sử dụng các quyền khác.`,
  p24: `7. Các nội dung video trong phần mềm này chỉ đại diện cho quan điểm và ý kiến của tác giả và không liên quan đến ${window.company} Công nghệ và chủ sở hữu bản quyền đã đăng ký của phần mềm. Tác giả phải chịu trách nhiệm pháp lý cho mọi nội dung.`,
  p25: `3. Giải thích cho người dùng`,
  p26: `1. Chức năng phần mềm: Tìm kiếm và xem phim ngắn; nạp tiền, tải xuống và mua phim ngắn; xem và lưu tiến độ xem.`,
  p27: `2. Về việc thanh toán nội dung số: Tất cả thu nhập từ việc người dùng thanh toán sẽ được công ty ${window.company} Công nghệ thu sau khi đã trừ đi các phí do tổ chức thanh toán bên thứ ba thu. Nội dung số trong phần mềm này bao gồm nội dung miễn phí và nội dung trả phí, được chia thành nội dung miễn phí có thời hạn và nội dung miễn phí vĩnh viễn. ${window.company} Công nghệ có quyền quyết định tiêu chuẩn dịch vụ và phương thức tính phí cung cấp dịch vụ, và sẽ cung cấp thông tin về tiêu chuẩn định giá trên trang dịch vụ liên quan. Người dùng có quyền chọn thanh toán phí tương ứng để tiếp tục sử dụng dịch vụ trả phí của phần mềm này. Nếu bạn là trẻ vị thành niên dưới 18 tuổi (đặc biệt là dưới 14 tuổi), bạn nên nạp tiền với sự đồng ý của người giám hộ hợp pháp. Sau khi nạp tiền trên phần mềm này, người dùng sẽ nhận được số điểm xem tương ứng, có thể dùng để mua dịch vụ trả phí do phần mềm này cung cấp. Sự đồng ý của bạn với thỏa thuận này được coi là sự đồng ý cho phép ${window.company} Công nghệ tự động mua nội dung của tập tiếp theo và mua hàng loạt nội dung loạt phim làm dịch vụ trả phí của bạn. Một khi người dùng đã nạp tiền thành công (bao gồm cả người chưa thành niên đã được sự đồng ý của người giám hộ), trừ các trường hợp bất khả kháng, thỏa thuận hoặc quy định pháp luật liên quan, người dùng không thể yêu cầu hoàn tiền toàn bộ hoặc một phần vì lý do không hợp pháp.`,
  p28: `3. Sửa đổi và nâng cấp phần mềm: Chủ sở hữu bản quyền phần mềm bảo lưu quyền cung cấp các phiên bản sửa đổi và nâng cấp cho người dùng vào bất kỳ lúc nào. Sau khi người dùng chọn và xác nhận, phần mềm sẽ được nâng cấp và cập nhật, và sẽ tạo ra phí lưu lượng dữ liệu tương ứng sử dụng mạng của nhà cung cấp dịch vụ, phí này sẽ do nhà cung cấp dịch vụ thu.`,
  p29: `4. Người dùng phải tuân thủ pháp luật khi sử dụng phần mềm này và tuân thủ thỏa thuận này. Người dùng không được thực hiện các hành vi không được ủy quyền sau đây, bao gồm nhưng không giới hạn:`,
  p30: `(1) Xóa hoặc thay đổi tất cả thông tin quản lý quyền sở hữu trong phần mềm này;`,
  p31: `(2) Cố ý bỏ qua hoặc phá hủy các biện pháp bảo vệ kỹ thuật mà chủ sở hữu bản quyền đã thực hiện để bảo vệ bản quyền phần mềm này;`,
  p32: `(3) Sử dụng phần mềm này để lừa đảo và lừa dối người khác;`,
  p33: `(4) Bằng cách xóa, thay đổi, thêm hoặc can thiệp vào chức năng của hệ thống thông tin máy tính vi phạm các quy định của quốc gia, dẫn đến chức năng của hệ thống thông tin máy tính bất thường;`,
  p34: `(5) Truy cập trái phép vào mạng thông tin máy tính hoặc sử dụng tài nguyên mạng thông tin máy tính;`,
  p35: `(6) Xóa, thay đổi hoặc thêm chức năng mạng thông tin máy tính trái phép;`,
  p36: `(7) Xóa, xâm nhập, thay đổi hoặc thêm dữ liệu và ứng dụng được lưu trữ, xử lý hoặc truyền tải trong mạng thông tin máy tính trái phép;`,
  p37: `(8) Can thiệp vào hoạt động bình thường của hệ thống phần mềm hoặc trang web này, cố ý phát tán virus máy tính hoặc các chương trình phá hoại khác;`,
  p38: `(9) Bất kỳ hành vi nào khác gây nguy hiểm đến an toàn của mạng thông tin máy tính;`,
  p39: `(10) Các hành vi sử dụng bất thường khác.`,
  p40: `5. Đối với phần mềm này được tải xuống từ trang web không được chỉ định hoặc nhận từ phương tiện phân phối bất hợp pháp, ${window.company} Công nghệ không thể đảm bảo phần mềm này có bị nhiễm virus máy tính hay không, có ẩn chứa chương trình Trojan giả mạo hoặc phần mềm hacker hay không. Người dùng sử dụng phần mềm này có thể gặp rủi ro không thể dự đoán trước. Khuyên người dùng không nên tải xuống, cài đặt hoặc sử dụng dễ dàng. ${window.company} Công nghệ và chủ sở hữu bản quyền đã đăng ký của phần mềm này không chịu trách nhiệm pháp lý cho bất kỳ hậu quả pháp lý nào do việc này gây ra.`,
  p41: `6. Bảo vệ quyền riêng tư`,
  p42: `${window.company} Công nghệ cam kết tuân thủ chính sách quyền riêng tư (còn gọi là "thỏa thuận quyền riêng tư") và các luật pháp liên quan, và đã xây dựng bốn nguyên tắc bảo vệ quyền riêng tư sau đây để hướng dẫn chúng tôi xử lý các vấn đề liên quan đến quyền riêng tư và thông tin của người dùng trong sản phẩm:`,
  p43: `(1) Trước khi thu thập thông tin của bạn, chúng tôi sẽ thông báo rõ ràng cho bạn về phương thức, phạm vi và mục đích thu thập thông tin, và nhận được sự đồng ý của bạn. Chúng tôi chỉ sử dụng thông tin chúng tôi thu thập để cung cấp các sản phẩm và dịch vụ có giá trị cho người dùng. Chúng tôi sẽ không bao giờ vượt quá phạm vi hoặc tần suất thu thập thông tin cá nhân của bạn, chẳng hạn như thông tin liên hệ, vị trí, số CMND, thông tin khuôn mặt, và sẽ không bao giờ thu thập thông tin không cần thiết hoặc không có ngữ cảnh hợp lý trong dịch vụ phần mềm này;`,
  p44: `(2) Phát triển các sản phẩm phù hợp với tiêu chuẩn quyền riêng tư và thực tiễn quyền riêng tư. Không được chia sẻ hoặc sử dụng thông tin cá nhân với các ứng dụng phần mềm khác mà không có sự đồng ý của người dùng, chẳng hạn như thông tin nhận dạng thiết bị, lịch sử duyệt sản phẩm, thói quen sử dụng tìm kiếm và danh sách ứng dụng phần mềm thường dùng. Không bao giờ sử dụng thông tin cá nhân đã thu thập của người dùng về lịch sử tìm kiếm, duyệt, thói quen sử dụng, vv để quảng bá định hướng hoặc tiếp thị chính xác mà không thông báo hoặc hiển thị rõ ràng cho người dùng, và không cung cấp tùy chọn tắt chức năng này cho người dùng;`,
  p45: `(3) Thu thập thông tin cá nhân một cách minh bạch;`,
  p46: `(4) Nỗ lực tối đa để bảo vệ thông tin người dùng mà chúng tôi thu thập được.`,
  p47: `Dựa trên điều này, bạn đồng ý ủy quyền cho ${window.company} Công nghệ sử dụng hình đại diện và biệt danh của bạn để chúng tôi có thể cung cấp dịch vụ tốt hơn cho bạn.`,
  p48: `7. Sau khi trở thành thành viên VIP, trong thời gian thành viên, bạn có thể xem tất cả các nội dung phim ngắn trên nền tảng này.`,
  p49: `8. Trong quá trình cài đặt và vận hành phần mềm này, ${window.company} Công nghệ sẽ không yêu cầu bất kỳ quyền nào không liên quan đến tình huống sử dụng hiện tại của người dùng. Hơn nữa, sau khi người dùng rõ ràng từ chối yêu cầu quyền, không nên yêu cầu kích hoạt các quyền không liên quan đến tình huống dịch vụ hiện tại, chẳng hạn như danh bạ, vị trí, tin nhắn, ghi âm, máy ảnh, vv để ép người dùng đồng ý kích hoạt các quyền không liên quan đến phần mềm này. Khi người dùng không sử dụng các chức năng hoặc dịch vụ liên quan, không nên yêu cầu kích hoạt trước các quyền liên quan đến danh bạ, vị trí, tin nhắn, ghi âm, máy ảnh của người dùng, hoặc yêu cầu kích hoạt các quyền liên quan đến các chức năng hoặc dịch vụ kinh doanh không liên quan của sản phẩm phần mềm này. Quyền bảng tạm chỉ được sử dụng trong tình huống người dùng chọn sao chép số điện thoại chăm sóc khách hàng. Nếu người dùng không chọn sao chép, ${window.company} Công nghệ sẽ không bao giờ yêu cầu kích hoạt quyền bảng tạm của người dùng.`,
  p50: `9. Người dùng có quyền hủy thông tin tài khoản cá nhân của mình. Sau khi hủy thông tin tài khoản cá nhân của người dùng, ${window.company} Công nghệ sẽ xóa thông tin tài khoản cá nhân của người dùng được lưu trữ trong phần mềm này (vui lòng tham khảo thông báo hủy liên quan để biết chi tiết).`,
  p51: `10. Khi bạn cài đặt lại hệ thống điện thoại hoặc dọn dẹp ứng dụng phần mềm điện thoại, tất cả thông tin người dùng của bạn sẽ bị mất, bao gồm nhưng không giới hạn: thông tin tài khoản cá nhân, lịch sử tìm kiếm hoặc duyệt phim, các phim đã tải xuống hoặc đã mua, lịch sử xem phim, tiến độ xem phim, quyền lợi thành viên, đồng thời tất cả lịch sử xem phim trong tài khoản cá nhân của bạn sẽ được đặt lại về số 0. Bạn biết rằng điều này là đặc điểm của sản phẩm, nhưng bạn có thể tránh mất thông tin người dùng liên quan khi cài đặt lại hệ thống điện thoại hoặc dọn dẹp ứng dụng phần mềm điện thoại bằng cách liên kết tài khoản cá nhân của bạn với số điện thoại. Khi bạn không thể liên kết tài khoản cá nhân với số điện thoại, trong trường hợp xảy ra sự cố mất thông tin người dùng, ${window.company} Công nghệ và chủ sở hữu bản quyền của phần mềm này sẽ không chịu trách nhiệm.`,
  p52: `11. Bạn không được thực hiện bất kỳ hoạt động bất hợp pháp hoặc tiêu thụ không bình thường nào trên nền tảng phần mềm này, cũng không được lợi dụng lỗi hệ thống, lỗi chương trình, lỗi thao tác của con người, vv. Bạn không được sử dụng phần mềm độc hại hoặc bất kỳ phương tiện nào khác để làm gián đoạn hoạt động bình thường của nền tảng phần mềm này. Bạn không được thực hiện bất kỳ hành động nào vi phạm nguyên tắc trung thực. Nếu nền tảng phần mềm này xác định rằng bạn có các hành vi đã nêu trên, ${window.company} Công nghệ có quyền đóng băng hoặc hủy tài khoản của bạn mà không hoàn lại số dư tài khoản. Mọi tổn thất do đó sẽ do bạn tự chịu. Ngoài ra, ${window.company} Công nghệ vẫn bảo lưu quyền truy cứu trách nhiệm pháp lý của bạn theo luật pháp. Nếu hành vi của bạn gây thiệt hại cho ${window.company} Công nghệ, bạn phải bồi thường toàn bộ thiệt hại của ${window.company} Công nghệ. Nếu bạn bị nghi ngờ phạm tội, ${window.company} Công nghệ có quyền chuyển giao các manh mối liên quan cho cơ quan tư pháp để xử lý.`,
  p53: `Chúng tôi rất coi trọng việc bảo vệ thông tin cá nhân và quyền lợi hợp pháp của trẻ em, và tích cực thúc đẩy sự phát triển lành mạnh của họ. Trẻ em (đặc biệt là trẻ dưới 14 tuổi) không được sử dụng dịch vụ dưới thỏa thuận này mà không có sự giám sát và đồng ý của cha mẹ (người giám hộ). Một khi phát hiện, cha mẹ (người giám hộ) phải thông báo ngay lập tức. Chúng tôi sẽ hỗ trợ cha mẹ (người giám hộ) xử lý tài khoản đăng ký và tài khoản, đảm bảo thực hiện trách nhiệm và nghĩa vụ giám hộ.`,
  p54: `4. Bảo vệ quyền sở hữu trí tuệ của người dùng và bên thứ ba`,
  p55: `1. Trong quá trình sử dụng dịch vụ có trong phần mềm này, người dùng có thể cần cung cấp nội dung cho ${window.company} Công nghệ bằng cách bình luận và các phương tiện khác. Tất cả nội dung gốc do người dùng đăng trên phần mềm này thuộc quyền sở hữu của người dùng. Bình luận của người dùng chỉ đại diện cho quan điểm của họ và không liên quan đến chủ sở hữu bản quyền đã đăng ký của phần mềm hoặc ${window.company} Công nghệ.`,
  p56: `2. Người dùng không được xâm phạm quyền sở hữu trí tuệ, bao gồm quyền tác giả và các quyền khác của người khác. Nếu nội dung do người dùng đăng gây ra tranh chấp pháp lý, trách nhiệm sẽ do người dùng tự chịu.`,
  p57: `3. Đối với bất kỳ nội dung nào do người dùng đăng trên phần mềm này, người dùng đồng ý ủy quyền cho ${window.company} Công nghệ quyền sử dụng miễn phí, vĩnh viễn, không độc quyền trên toàn cầu. Người dùng cho phép ${window.company} Công nghệ thực hiện các hành động pháp lý đơn phương đối với bất kỳ hành vi xâm phạm nào (bao gồm nhưng không giới hạn việc kiện tụng, báo cáo, gửi thư yêu cầu từ luật sư, vv).`,
  p58: `4. Nếu bình luận của người dùng xâm phạm quyền tác giả hoặc các quyền khác của bên thứ ba, hoặc vi phạm các quy định pháp luật, khi bên thứ ba có ý kiến hoặc thông qua quy trình kiểm duyệt nội dung phát hiện, ${window.company} Công nghệ có quyền xóa nội dung liên quan và có quyền truy cứu hoặc hỗ trợ bên thứ ba truy cứu trách nhiệm pháp lý của người dùng.`,
  p59: `5. Không có sự đồng ý bằng văn bản của ${window.company} Công nghệ, bất kỳ thực thể nào không được sử dụng bất kỳ chương trình robot, chương trình spider, chương trình human-form, công nghệ thâm nhập, chương trình hacker và các thiết bị tự động khác, hoặc chương trình thủ công để xâm nhập, đọc, sao chép, lưu trữ phần mềm này hoặc bất kỳ nội dung nào trong đó, hoặc thực hiện bất kỳ hoạt động bất hợp pháp hoặc không trung thực nào. Nếu không, ${window.company} Công nghệ có quyền đóng băng hoặc hủy tài khoản của bạn mà không hoàn lại số dư tài khoản. Mọi tổn thất do đó sẽ do bạn tự chịu. Ngoài ra, ${window.company} Công nghệ vẫn bảo lưu quyền truy cứu trách nhiệm pháp lý của bạn theo luật pháp để bảo vệ quyền lợi hợp pháp của ${window.company} Công nghệ.`,
  p60: `5. Tuyên bố quyền tác giả nội dung`,
  p61: `${window.company} Công nghệ cam kết tuân thủ và tôn trọng các quyền bảo vệ của chủ sở hữu bản quyền theo luật pháp Trung Quốc hiện hành, quy định và các văn bản chuẩn khác. Với tư cách là quyền sở hữu, khi bạn phát hiện nội dung được cung cấp vi phạm quyền lợi hợp pháp của bạn, bạn nên gửi "Thông báo quyền" cho chúng tôi theo quy trình xử lý đến địa chỉ email cs{'@'}ishugui.com hoặc gọi số điện thoại chăm sóc khách hàng 400-118-0066. Chúng tôi sẽ thực hiện các biện pháp theo quy định pháp luật Trung Quốc và văn bản chuẩn của chính phủ để xóa nội dung liên quan hoặc ngắt kết nối hoặc chặn liên kết liên quan. Sau khi thực hiện các biện pháp trên, bạn không được truy cứu trách nhiệm pháp lý của chúng tôi và chủ sở hữu bản quyền đã đăng ký của phần mềm này.`,
  p62: `6. Miễn trách nhiệm và giới hạn trách nhiệm`,
  p63: `1. Người dùng xác nhận đã hiểu rõ tất cả các chức năng của phần mềm này và các thao tác cần thiết để thực hiện từng chức năng. Họ tự nguyện chọn sử dụng phần mềm và dịch vụ liên quan theo nhu cầu của mình. Theo quy định pháp luật, bạn sẽ hoàn toàn chịu rủi ro và mọi hậu quả do việc sử dụng phần mềm và dịch vụ liên quan gây ra. Chủ sở hữu bản quyền đã đăng ký của phần mềm này và ${window.company} Công nghệ không chịu trách nhiệm pháp lý.`,
  p64: `2. Phần mềm này đã được kiểm tra chi tiết, nhưng chúng tôi không thể đảm bảo tương thích hoàn toàn với tất cả hệ thống phần mềm và phần cứng, cũng không thể đảm bảo phần mềm này hoàn toàn không có lỗi. Nếu có vấn đề về tương thích và lỗi phần mềm, người dùng có thể gọi số điện thoại chăm sóc khách hàng 400-118-0066 để báo cáo tình trạng và nhận hỗ trợ kỹ thuật. Nếu không thể giải quyết vấn đề tương thích, người dùng có thể xóa phần mềm này.`,
  p65: `3. Trong phạm vi pháp luật cho phép, chủ sở hữu bản quyền đã đăng ký của phần mềm này và ${window.company} Công nghệ không chịu trách nhiệm pháp lý cho bất kỳ thiệt hại và rủi ro nào gây ra bởi việc bạn sử dụng hoặc không thể sử dụng phần mềm này, bao gồm nhưng không giới hạn thiệt hại trực tiếp hoặc gián tiếp cá nhân, mất lợi nhuận kinh doanh, gián đoạn giao dịch, mất thông tin kinh doanh hoặc bất kỳ thiệt hại kinh tế nào khác.`,
  p66: `4. Chủ sở hữu bản quyền đã đăng ký của phần mềm này và ${window.company} Công nghệ không chịu trách nhiệm pháp lý cho bất kỳ mất mát hoặc hư hỏng thông tin nào gây ra bởi sự cố hệ thống viễn thông hoặc mạng Internet, sự cố điện thoại hoặc chương trình virus, hoặc bất kỳ nguyên nhân bất khả kháng nào khác.`,
  p67: `5. Nếu người dùng vi phạm các quy định của thỏa thuận này, gây ra tổn thất cho chủ sở hữu bản quyền đã đăng ký của phần mềm này và ${window.company} Công nghệ, ${window.company} Công nghệ có quyền thực hiện các biện pháp bao gồm nhưng không giới hạn quyền gián đoạn quyền sử dụng của người dùng, ngừng cung cấp dịch vụ, hạn chế sử dụng và truy cứu trách nhiệm pháp lý.`,
  p68: `7. Pháp luật và giải quyết tranh chấp`,
  p69: `1. Thỏa thuận này được điều chỉnh bởi luật pháp của Cộng hòa Nhân dân Trung Hoa.`,
  p70: `2. Bất kỳ tranh chấp nào phát sinh từ thỏa thuận này hoặc liên quan đến thỏa thuận này phải được giải quyết thông qua thương lượng thân thiện giữa các bên; nếu thương lượng thất bại, bất kỳ bên nào đều có quyền nộp tranh chấp cho Ủy ban Trọng tài để phân xử.`,
  p71: `8. Các điều khoản khác`,
  p72: `1. Nếu bất kỳ điều khoản nào của thỏa thuận này vì bất kỳ lý do nào mà hoàn toàn hoặc một phần không hợp lệ hoặc không thể thi hành, hoặc vi phạm bất kỳ luật áp dụng nào, thì điều khoản đó sẽ được coi là đã bị xóa, nhưng các điều khoản còn lại của thỏa thuận này vẫn có hiệu lực và có tính ràng buộc.`,
  p73: `2. ${window.company} Công nghệ có quyền sửa đổi thỏa thuận này vào bất kỳ lúc nào theo sự thay đổi của luật pháp liên quan và tình hình kinh doanh và chiến lược của công ty. Thỏa thuận sửa đổi sẽ được công bố trên trang web và đính kèm trong phiên bản mới của phần mềm. Khi có tranh chấp, văn bản thỏa thuận mới nhất sẽ được ưu tiên. Nếu người dùng không đồng ý với sự thay đổi, họ có thể tự xóa phần mềm. Nếu người dùng tiếp tục sử dụng phần mềm, điều đó được coi là họ đã chấp nhận các thay đổi của thỏa thuận này.`,
  p74: `3. ${window.company} Công nghệ có quyền giải thích và sửa đổi thỏa thuận này, nhưng không được vượt quá phạm vi pháp luật cho phép tối đa.`,

  login_first: `Bạn chưa đăng nhập, vui lòng đăng nhập trước`,
  sessionTimeout: `Phiên đã hết hạn, vui lòng đăng nhập lại`,
  unknown_err: `Lỗi không xác định`,
  way: `Cách thức`,

  starCardPlan: `Kế hoạch Thẻ Ngôi Sao`,
  starCardPrivate: `Quyền lợi Thẻ Ngôi Sao`,
  starCardIntroduction: `Thẻ Ngôi Sao là vé tháng trong hệ thống quảng bá của Jubaopen. Sở hữu Thẻ Ngôi Sao, bạn mới có thể hưởng các lợi ích và phúc lợi trong hệ thống quảng bá của Jubaopen. Thẻ Ngôi Sao được chia thành Thẻ Ngôi Sao Trải Nghiệm và Thẻ Ngôi Sao Chuyên Dụng. Trong thời gian trợ cấp hàng tỷ, người dùng Thẻ Ngôi Sao đều có thể nhận trợ cấp tiền mặt.`,

  banQuanZhongChou: `Gây quỹ bản quyền`,
  banQuan1: `Lợi nhuận cố định 1.3 lần, nhiều phần thưởng linh hoạt hơn`,
  banQuan2: `Lợi nhuận cố định 1.5 lần, nhiều phần thưởng linh hoạt hơn`,

  enterPhoneNumber: `Vui lòng nhập số điện thoại của bạn`,
  enterRightNumber: `Vui lòng nhập đúng số điện thoại`,
  pwdNotNull: `Mã xác thực không thể để trống`,
  checkAgreement: `Vui lòng đồng ý với các điều khoản quyền riêng tư và thoả thuận người dùng`,
  second: `giây`,
  sendSuccess: `Gửi thành công`,
  codeValid: `Nhận mã xác thực`,

  promoteCount: `Số người quảng bá`,
  today: `Hôm nay`,
  promoteList: `Danh sách quảng bá`,
  nickName: `Biệt danh`,
  level: `Cấp độ`,
  register: `Đăng ký`,
  noMore: `Không có thêm nội dung`,
  page: `Trang`,
  drawout: `Rút tiền`,
  logining: `Đang đăng nhập...`,
  shortMoive: `Phim ngắn`,
  otherLogin: `Đăng nhập bằng phương thức khác`,

  uploading: `Đang tải lên...`,
  timeout: `Kết nối quá thời gian, vui lòng kiểm tra mạng`,
  uploadLimitOne: `Chỉ có thể chọn một ảnh để tải lên`,
  unit: window.currency,

  drawoutNotice: `<p>1. Vui lòng điền thông tin liên quan đến việc rút tiền một cách trung thực. Nếu có bất kỳ tổn thất nào do thông tin không chính xác, nền tảng sẽ không chịu trách nhiệm.</p>
  <p>2. Các lệnh rút tiền cần được kiểm tra tài chính và có thể sẽ có một số chậm trễ trong thời gian nhận tiền.</p>
  <p>3. Số tiền rút tối thiểu cho mỗi giao dịch ${window.currency}$extractSingleMin。</p>
  <p>4. Số tiền rút tối đa cho mỗi giao dịch ${window.currency}$extractSingleMax。</p>
  <p>5. Hoa hồng $extractChargeRate , khấu trừ từ số tiền rút。</p>
  <p>6. Số lần rút tiền tối đa hàng ngày $extractDailyCount lệnh</p>
  <p>7. Nhận trong vòng 24 giờ sau khi rút tiền。</p>`,

  submitItem: `Gửi`,
  caresure: `Lưu ý`,
  server_bank: `TÊN NGÂN HÀNG`,
  server_bankCardNo: `SỐ TÀI KHOẢN`,
  server_bankCardHolder: `CHỦ TÀI KHOẢN`,
  server_email: `Email`,
  server_phone: `Điện thoại`,

  pleaseInputPwd: `Vui lòng nhập mật khẩu`,
  useCodeLogin: `Chuyển sang đăng nhập mã xác minh`,
  usePwdLogin: `Chuyển sang đăng nhập bằng mật khẩu`,
  pwdLimitTip: `Độ dài mật khẩu nên từ 6-20 ký tự`,
  pleaEnterInviteCode: `Vui lòng nhập mã mời`,
  setPwd: `Đặt mật khẩu đăng nhập`,
  pwdPlaceholder: `Vui lòng nhập mật khẩu từ 6-20 ký tự`,
  optSuccess: `Thao tác thành công`,
  Balance: `Số dư`, // Balance
  WithdrawAmountTip: `Số tiền rút không được để trống`, // Withdrawal Amount cannot be empty
  WithdrawAmountTipMin: `Số dư của bạn thấp hơn số tiền rút tối thiểu`, // Your balance is less than the minimum withdrawal amount
  WithdrawAmountTipDecimal: `Số tiền rút không được vượt quá hai chữ số thập phân`, // The withdrawal amount cannot exceed two decimal places
  TransferAmountTip: `Số tiền chuyển không được để trống`, // Transfer amount cannot be empty
  TransferAmountTipMin: `Số dư của bạn thấp hơn số tiền chuyển tối thiểu`,
  TransferAmountTipDecimal: `Số tiền chuyển không được vượt quá hai chữ số thập phân`,
  Submit: `Xác nhận`,

  BankOfDeposit: `Ngân hàng gửi tiền`, // Deposit of Bank
  BankOfDepositTip: `Tên ngân hàng không được để trống`, // Bank name cannot be empty
  BankCardNumber: `Số thẻ ngân hàng`, // Bank Card Number
  BankCardNumberTip: `Số thẻ ngân hàng không được để trống`, // Bank card number cannot be empty
  userName: `Tên người dùng`, // Username
  userNameTip: `Tên người dùng không được để trống`, // Username cannot be empty

  TransferUserID: `ID người dùng chuyển tiền`, // Transfer User ID
  TransferUserIDTip: `ID người dùng chuyển tiền không được để trống`, // Transfer user ID cannot be empty
  ReTransferUserID: `Nhập lại ID người dùng chuyển tiền`, // Re-enter Transfer User ID
  ReTransferUserIDTip: `Nhập lại ID người dùng chuyển tiền không được để trống`, // Re-enter Transfer User ID cannot be empty
  TransferUserIDTips: `ID người dùng chuyển tiền đã nhập lại không khớp với ID người dùng chuyển tiền`, // The re-entered transfer user ID does not match the transfer user ID

  VerificationCode: `Mã xác minh`, // Verification Code
  VerificationCodeTip: `Mã xác minh không được để trống`, // The verification code cannot be empty
  GetVerificationCode: `Nhận mã xác minh`, // Get Verification Code

  Precautions: `Lưu ý`, // Precautions
  PrecautionsTip1: `1. Vui lòng điền chính xác thông tin liên quan đến rút tiền. Nếu có bất kỳ tổn thất nào do thông tin không chính xác gây ra, nền tảng sẽ không chịu trách nhiệm.`, // 1.Please truthfully fill in the withdrawal related information. If any loss is caused due to incorrect information, the platform will not be held responsible.
  PrecautionsTip2: `2. Yêu cầu rút tiền cần được xem xét về tài chính, và có thể có một số chậm trễ trong thời gian đến.`, // 2.Withdrawal orders require financial review, and there may be a certain delay in the arrival time.
  PrecautionsTip3: `3. Số tiền rút tối thiểu cho một giao dịch là slot.`, // 3.Minimum withdrawal amount for a single transaction 1.
  PrecautionsTip4: `4. Số tiền rút tối đa cho mỗi giao dịch là slot.`, // 4.Maximum withdrawal amount per transaction 1.
  PrecautionsTip5: `5. Phí slot, được khấu trừ từ số tiền rút.`, // 5.Commission 1,deduction from withdrawal amount.
  PrecautionsTip6: `6. Tối đa slot lệnh rút tiền hàng ngày.`, // 6.Maximum daily withdrawal 1 orders
  PrecautionsTip7: `7. Nhận trong vòng 24 giờ sau khi rút tiền.`, // 7.Received within 24 hours after withdrawal.

  confirmPay: `Xác nhận thanh toán $y`,
  goPay: `Thanh toán ngay $y`,
  couponState: `Đã sử dụng hoặc không phù hợp với loại hiện tại`,
  couponNo: `Phiếu giảm giá hiện tại không khả dụng`,
  coupinErr: `Lỗi phiếu giảm giá`,
  couponUse: `Giảm giá phiếu giảm giá $y`,
  BalanceDeductions: `Giảm giá bằng số dư $y`,
  ExchangeStarCard: `Trao đổi thẻ Sao`,
  EnterMembership: `Đổi M-Card hoặc M-Card Pro trong -x ngày`,
  ExchangeNotUsed: `Mã trao đổi chưa được sử dụng`,
  ExchangeUsed: `Mã trao đổi đã được sử dụng`,
  noExchange: `Mã trao đổi không tồn tại`,
  PleaseAmount: `Vui lòng chọn số tiền tham gia:`,
  PaymentAgreement: `Thanh toán được xem như sự đồng ý của bạn`,
  DramaAgreement: `Thoả thuận Dịch vụ Drama Rich Star Card`,
  setPayment: `Cài Đặt Phương Thức Thu`,
  saveInfo: `Lưu Thông Tin`,
  myBuy: `Mua`,
  mySell: `Bán`,
  placeOrder: `Đặt Hàng`,
  whole: `Tất Cả`,
  myOrder: `Đơn Hàng Của Tôi`,
  anonymousUser: `Người Dùng Ẩn Danh`,
  deal: `Giao Dịch`,
  sell: `Bán`,
  integral: `Điểm`,
  demand: `Nhu Cầu`,
  minute: `Phút`,
  sellOut: `Bán Hết`,
  purchase: `Mua`,
  pendingOrders: `Đơn Hàng Đang Chờ`,
  confirmOrder: `Xác Nhận Đơn Hàng`,
  choosePaymentMethod: `Chọn Phương Thức Thanh Toán`,
  back: `Quay Lại`,
  orderGenerated: `Đơn Hàng Đã Được Tạo`,
  please: `Xin Vui Lòng`,
  paymentInformation: `Xác Nhận Thông Tin Thanh Toán Của Người Mua`,
  reportExceptions: `Báo Cáo Lỗi`,
  sellerToConfirm: `Chờ Người Bán Xác Nhận Thanh Toán`,
  ordersCannotStage: `Đơn Hàng Không Thể Hủy Ở Giai Đoạn Này`,
  orderCompleted: `Đơn Hàng Đã Hoàn Thành`,
  contactustomer: `Nếu có bất kỳ phản đối nào, vui lòng liên hệ với dịch vụ khách hàng để được hòa giải`,
  customerServiceMediation: `Hòa Giải Dịch Vụ Khách Hàng`,
  confirmCompletion: `Xác Nhận Hoàn Thành`,
  PleaseWaitPayment: `Xin Vui Lòng Chờ Thanh Toán Của Người Mua`,
  reportCorrect: `Báo Cáo Lỗi Đơn Hàng, Có Đúng Không?`,
  addMethod: `Thêm Phương Thức Thanh Toán`,
  selectType: `Chọn Loại`,
  sellPoints: `Bán Điểm`,
  sellUsers: `Bán Điểm Từ Tài Khoản Cho Người Dùng Khác`,
  purchasePoints: `Mua Điểm`,
  canPointsUsers: `Có Thể Mua Điểm Từ Người Dùng Khác`,
  messageBoard: `Bảng Tin`,
  you: `Bạn`,
  reply: `Trả Lời`,
  enterBelow: `Xin Vui Lòng Nhập Thông Tin Phản Hồi Dưới Đây`,
  viewDetails: `Xem Chi Tiết`,
  paymentMethod: `Phương Thức Thanh Toán`,
  addPaymentInformation: `Thêm Thông Tin Thanh Toán`,
  paymentDeadline: `Hạn Thanh Toán`,
  totalAmount: `Tổng Số Tiền`,
  price: `Giá Đơn Vị`,
  tradingPrecautions: `Cảnh Báo Giao Dịch`,
  afterQuantity: `1. Sau khi xác nhận đơn hàng, điểm của bạn sẽ bị khóa theo số lượng đơn hàng.`,
  confirmCancelled: `2. Vui lòng xác nhận hoàn thành giao dịch trong thời gian quy định, nếu không, nó sẽ tự động bị hủy.`,
  cancellationOrder: `Hủy Đơn Hàng`,
  CancelOrder: `Hủy Niêm Yết`,
  transactionDetails: `Chi Tiết Giao Dịch`,
  myPendingOrder: `Đơn Đặt Của Tôi`,
  orderType: `Loại Đơn Hàng`,
  myTransaction: `Giao Dịch Của Tôi`,
  orderStatus: `Tình Trạng Đơn Hàng`,
  buyPoints: `Điểm Mua`,
  release: `Phát Hành`,
  locking: `Khóa`,
  end: `Kết Thúc`,
  removeFromShelves: `Gỡ Bỏ Kệ`,
  establish: `Tạo`,
  approachingTimeout: `Sắp Hết Thời Gian`,
  overtime: `Hết Thời Gian`,
  buyerConfirmed: `Người Mua Đã Xác Nhận`,
  sellerConfirmed: `Người Bán Đã Xác Nhận`,
  successfulTrade: `Giao Dịch Thành Công`,
  createOrder: `Tạo Đơn Mua`,
  createSellOrder: `Tạo Đơn Bán`,
  enterPurchaseQuantity: `Nhập Số Lượng Mua`,
  enterSale: `Nhập Số Lượng Bán`,
  pointsBalance: `Số Dư Điểm`,
  setMaxQuantity: `Đặt Số Lượng Tối Đa`,
  setPurchasePrice: `Đặt Giá Mua (Tổng Giá)`,
  setSalePrice: `Đặt Giá Bán (Tổng Giá)`,
  rangePrice: `Khoảng Giá Điểm`,
  confirmPeddingOrder: `Xác Nhận Niêm Yết`,
  pointsRange: `Giá Điểm Vượt Quá Phạm Vi`,
  orderSuccessfully: `Đơn Hàng Đã Được Đặt Thành Công`,
  OrderErr: `Lỗi Đơn Hàng`,
  OrderOverTime: `Đơn Hàng Hết Hạn`,
  CustomeRservice: `Nếu có bất kỳ khiếu nại nào, vui lòng liên hệ với bộ phận chăm sóc khách hàng để hòa giải`,
  UpdataPedding: `Sửa Đơn Hàng Chờ Xử Lý`,
  CustomerService: `Hòa Giải Của Bộ Phận Chăm Sóc Khách Hàng`,
  WaitingPayment: `Đang Chờ Thanh Toán`,
  Collectionpayment: `Đang Chờ Thu Tiền`,
  ConfirmCompletion: `Xác Nhận Hoàn Thành`,
  ContactCustomer: `Liên Hệ Với Bộ Phận Chăm Sóc Khách Hàng Để Hòa Giải`,
  NumberPending: `Số Lượng Đơn Hàng Chờ Xử Lý Hôm Nay`,
  Edit: `Sửa`,
  Create: `Tạo`,
  Bill: `Hóa Đơn`,
  SellBill: `Bán Hóa Đơn`,
  PendingOrders: `Đơn Hàng Chờ Xử Lý`,
  Reason: `Lý Do`,
  Kong: `Không`,
  USDTAmout: `Sau khi rút về USDT, số tiền là $slot`,
  enterPassword: `Vui lòng nhập mật khẩu`,
  enterSecondPassword: `Vui lòng nhập mật khẩu xác nhận`,
  confirmPassword: `Hai mật khẩu không khớp`,
  registerIn: `Đăng ký và đăng nhập ngay`,
  LoginCode: `Đăng nhập bằng mã xác minh`,
  LoginPassword: `Đăng nhập bằng mật khẩu`,
  goLogin: `Đi đến đăng nhập`,
  goRegister: `Đi đến đăng ký`,

  WALLET: 'Ví tiền',
  THIRD_PAY: 'Thanh toán thứ ba',
  Using: `Đang sử dụng`,
  SwitchAccount: `Chuyển tài khoản`,
  AddAccount: `Thêm tài khoản`,
  Finish: `Hoàn thành`,
  ClearAccount: `Bạn có chắc chắn muốn xóa tài khoản của mình không?`,

  AppDesc: `Nền tảng sáng tạo nối dung phim ngắn`,
  AppIntroduce: `MovShot là một nền tảng tiên tiến kết hợp giải trí và cơ hội kiếm tiền cho người dùng thích xem phim ngắn. Nền tảng này cho phép người dùng kiếm tiền bằng cách tham gia vào các bộ phim ngắn thông qua nhiều tính năng tương tác như xem, bình luận và chia sẻ.`,
  DownloadNow: `Tải ngay`,
  SignIn: `Đăng nhập`,
  MarketBackground: `Bối cảnh thị trường`,
  MarketDesc: `Với hơn 1 tỷ người dùng video ngắn trên toàn cầu, 50,4% đã xem phim truyền hình ngắn có độ dài dưới 3 phút. Dự báo của ngành cho thấy đến năm 2023, quy mô thị trường phim truyền hình ngắn có thể đạt từ 2,8 tỷ đến 4,2 tỷ đô la, với mức tăng trưởng liên tục dự kiến đến năm 2024.`,
  ProductFeatures: `Tính năng sản phẩm`,
  AppFeature1: `1.Nội dung đa dạng: `,
  AppFeatureDesc1: `MovShot cung cấp một nền tảng phim ngắn phong phú, bao gồm các thể loại như hài kịch, lãng mạn, hành động, v.v., đáp ứng nhiều sở thích khác nhau của người dùng.`,
  AppFeature2: `2.Mô hình kiếm tiền: `,
  AppFeatureDesc2: `MovShot cho phép người dùng kiếm tiền bằng cách xem phim, bình luận và chia sẻ các bộ phim ngắn. Mô hình kiếm tiền độc đáo này khiến nó trở nên hấp dẫn hơn với người dùng.`,
  AppFeature3: `3.Cộng đồng tương tác: `,
  AppFeatureDesc3: `MovShot thúc đẩy ý thức cộng đồng mạnh mẽ bằng cách cho phép người dùng thích, bình luận và chia sẻ nội dung, khuyến khích sự tương tác tích cực giữa người dùng.`,
  AppFeature4: `4.Khuyến nghị được cá nhân hóa: `,
  AppFeatureDesc4: `Sử dụng các thuật toán thông minh, MovShot cung cấp các đề xuất nội dung được cá nhân hóa dựa trên sở thích của người dùng, tăng cường sự tương tác và sự hài lòng.`,
  CompetitiveAdvantages: `Lợi thế cạnh tranh`,
  CompetitiveAdvantages1: `1.Khuyến mãi và cấp phép mở đại lý: `,
  CompetitiveAdvantages1_1: `MovShot khai thác các giấy phép mở, cho phép quảng bá nội dung chưa được khai thác trên nền tảng này và hiện thực hóa toàn bộ tiềm năng thương mại của nó.`,
  CompetitiveAdvantages2: `2.Phim ngắn chất lượng cao tự sản xuất: `,
  CompetitiveAdvantages2_1: `MovShot đầu tư vào việc tạo ra những bộ phim ngắn chất lượng cao, làm phong phú thêm nội dung nền tảng và tăng cường khả năng giữ chân người dùng.`,
  CompetitiveAdvantages3: `3.Quản lý IP của diễn viên Micro-Drama: `,
  CompetitiveAdvantages3_1: `Bằng cách phát triển IP cho các diễn viên kịch nhỏ, MovShot tận dụng ảnh hưởng của các diễn viên để tăng cường khả năng hiển thị của nền tảng, thúc đẩy sự tham gia của người dùng và chuyển đổi lưu lượng truy cập.`,
  CardTitle1: `Bố trí chuỗi ngành công nghiệp`,
  CardDesc1: `MovShot tích hợp quy trình sản xuất và phân phối nội dung, với chủ sở hữu bản quyền, nhà phân phối và đại lý phát trực tuyến làm việc cùng nhau để đảm bảo luồng nội dung thông suốt từ đầu tuyến đến cuối tuyến.`,
  CardTitle2: `Hệ thống thẻ M`,
  CardDesc2: `Thẻ M là thông tin xác thực để người dùng tham gia chương trình kiếm tiền của MovShot Pro. Có hai loại, Thẻ dùng thử M và Thẻ cao cấp M, cả hai đều cho phép quyền truy cập vào phần thưởng tiền mặt và các lợi ích khác.`,
  CardTitle3: `Đầu tư bản quyền`,
  CardDesc3: `MovShot cung cấp một cơ hội đầu tư độc đáo, nơi người dùng có thể đầu tư vào việc quảng bá các bộ phim truyền hình có bản quyền, kiếm được một phần lợi nhuận thu được. Lợi nhuận dao động dựa trên chu kỳ quảng bá của mỗi bộ phim.`,
  CardTitle4: `Thu nhập cộng đồng và nhóm`,
  CardDesc4: `MovShot giới thiệu hệ thống kiếm tiền dựa trên nhóm và cộng đồng, cho phép người dùng thành lập nhóm và sử dụng mạng lưới của họ để tối đa hóa thu nhập. Các chương trình khuyến mãi dựa trên nhóm mở khóa phần thưởng và tiền thưởng bổ sung, thúc đẩy sức hấp dẫn của nền tảng.`,
  UserFeedback: `Phản hồi của người dùng`,
  UserFeadbackDesc: `MovShot đã nhận được phản hồi tích cực từ người dùng!`,
  Career1: `Học sinh`,
  Career2: `Giáo viên`,
  Career3: `Lập trình viên`,
  Career4: `Tài xế`,
  UserTalk1: `"Tôi có thể kiếm tiền trong khi giải trí—thật tuyệt vời!"`,
  UserTalk2: `· "Các phím ngắn rất phong phú và hấp dẫn, mô hình kiếm tiền cũng hấp dẫn không kém."`,
  UserTalk3: `"Các đề xuất được cá nhân hóa của MovShot luôn giúp tôi tìm thấy những bộ phim phim mà tôi yêu thích."`,
  UserTalk4: `·"Cộng đồng tương tác giúp tôi kết bạn mới."`,
  Platfrom: `Tầm nhìn nền tảng`,
  PlatformDesc: `MovShot hướng đến mục tiêu tiếp tục nâng cao nội dung, trải nghiệm người dùng và cơ sở người dùng trong khi khám phá các chiến lược kiếm tiền mới để tăng trưởng bền vững. Bằng cách luôn theo kịp xu hướng thị trường và nhu cầu của người dùng, MovShot sẽ thành công liên tục trong thị trường phim ngắn đang phát triển`,
  Movshot: `Movshot`,
  Contact: `Liên hệ`,

  ContactDesc: `Chúng tôi là một đội ngũ thân thiện , luôn mong muốn được lắng nghe ý tưởng và những phản hồi của bạn.`,
  Message: `Tin nhắn...`,
  EmailErr: `Lỗi định dạng email`,
  FormErr: `Vui lòng điền vào mẫu đơn đầy đủ`,
  FormDesc: `Nhập ít nhất 10 ký tự`,
  noPaymentChannel:`Không có kênh thanh toán`,
  pleaSelectCoinType: `Vui lòng chọn loại tiền tệ`,
};
